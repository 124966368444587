import React from 'react';
import PropTypes from 'prop-types';
import { css, keyframes, useTheme } from '@emotion/react';
import LogoBrandIcon from '../common/Icons/LogoBrandIcon';

const rotateZ = keyframes`
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
`;
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Preloader = (props) => {
  const { isDefault = false } = props;
  const theme = useTheme();

  const preloaderCss = css({
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    background: '#fff',
    opacity: 0.8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #fff',
    zIndex: 101,
  });
  const animationCss = css({
    animationName: rotateZ,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    height: '50px',
  });

  if (isDefault) {
    const loaderCss = css({
      border: '8px solid #f3f3f3',
      borderTop: `8px solid ${theme.colors?.primary}`,
      borderRadius: '50%',
      width: '60px',
      height: '60px',
      minWidth: '60px',
      minHeight: '60px',
      animation: `${spin} 2s linear infinite`,
    });

    return (
      <div css={preloaderCss}>
        <div css={loaderCss} />
      </div>
    );
  }

  return (
    <div css={preloaderCss}>
      <LogoBrandIcon
        wrapCssClass={animationCss}
        width={80}
        height={50}
        isLogo
      />
    </div>
  );
};

Preloader.propTypes = {
  isDefault: PropTypes.bool,
};

export default Preloader;
