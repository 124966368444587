import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Link } from 'react-router-dom';
import { Button } from 'clevergig-lib';
import { withNavigation } from '../../helpers/hooksHelper';
import cookieHelper from '../../utils/cookieHelper';
import globalConst from '../../constants';
import treasureImg from '../../../theme/img/treasure-map.png';
import rocketSvg from '../../../theme/img/rocket.svg';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorPath: '',
      isChunkError: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (prevState.hasError && (location.pathname !== prevState.errorPath)) {
      this.handleClearError();
    }
  }

  componentDidCatch(error, errorInfo) {
    const { networkStatus, profile } = this.context;
    const { location } = this.props;
    // Catch errors in any components below and re-render with error message
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    const isOffline = networkStatus === globalConst.OFFLINE_STATUS;
    const isChunkError = !isOffline && chunkFailedMessage.test(error.message);
    this.setState({
      errorPath: location.pathname,
      hasError: true,
      isChunkError,
    }, () => {
      if (isChunkError) {
        setTimeout(() => this.handleReload(), 5000);
        return;
      }
      Sentry.withScope((scope) => {
        scope.setExtra('internal', errorInfo);
        scope.setTag('user_type', profile.user_type);
        Sentry.captureException(error);
      });
    });
  }

  handleClearError = () => this.setState({
    errorPath: '',
    hasError: false,
  });

  handleLogout = () => {
    const { currentLang } = this.context;

    cookieHelper.remove('type');
    cookieHelper.remove('token');

    this.props.onNavigate(`/${currentLang}/login`);
  };

  handleReload = () => document.location.reload(true);

  render() {
    const {
      profile,
      currentLang,
      localization: tr,
    } = this.context;
    const {
      isChunkError,
      hasError,
    } = this.state;

    if (hasError) {
      // Error path
      return (
        <div className="errorBoundary">
          <div className="errorBoundary__overlay" />
          <div className="errorBoundary__container">
            <div className="errorBoundary__body">
              <img
                className="errorBoundary__body-image"
                src={isChunkError ? rocketSvg : treasureImg}
                alt="error-img"
              />

              <h1>
                {isChunkError ? tr.dashboard.errorChunkTitle : tr.dashboard.errorTitle}
              </h1>

              {!isChunkError && (
                <div className="errorBoundary__body-description">
                  {tr.dashboard.errorDescription}
                </div>
              )}
              {!isChunkError && profile.email && (
                <div className="errorBoundary__body-action">
                  <div>
                    {tr.common.backTo}
                  </div>
                  <Link
                    className="link link__green --marginLeft-10"
                    to={`/${currentLang}/dashboard`}
                  >
                    {tr.common.dashboard}
                  </Link>
                </div>
              )}

              {!isChunkError && (
                <div className="errorBoundary__body-action">
                  <div>
                    {tr.common.backTo}
                  </div>
                  <Button
                    className="--marginLeft-10"
                    color="light"
                    onClick={this.handleLogout}
                  >
                    {tr.common.login}
                  </Button>
                </div>
              )}

              <div className="errorBoundary__body-action">
                <div>
                  {isChunkError ? tr.dashboard.errorChunkAction : tr.dashboard.errorAction}
                </div>
                <Button
                  className="--marginLeft-10"
                  color="light"
                  onClick={this.handleReload}
                >
                  {tr.common.refresh}
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';
ErrorBoundary.contextTypes = {
  localization: PropTypes.object.isRequired,
  currentLang: PropTypes.string.isRequired,
  networkStatus: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
};
ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onNavigate: PropTypes.func.isRequired,
};

export default withNavigation(ErrorBoundary);
